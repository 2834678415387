:root {
    --hd-mobile-header-height: var(--hd-space-8);
}

.hd-mobile-menu {
    background: var(--hd-color-neutral-surface);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: var(--hd-z-index-800);
    opacity: 0;
}

.hd-mobile-menu__header {
    display: flex;
    align-items: center;
    border-bottom: var(--hd-border-size) solid var(--hd-color-neutral-border);
    height: var(--hd-mobile-header-height);
}

.hd-mobile-menu__close {
    margin-inline-start: auto
}

.hd-mobile-menu__container {
    width: min(100% - calc(var(--hd-space-5) * 2));
    margin: 0 auto;
}

.hd-mobile-menu-nav {
    display: flex;
    padding-block: var(--hd-space-4);
}

.hd-mobile-menu-nav-list {
    list-style-type: none;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: var(--hd-space-05);
    line-height: 1.5;
    font-weight: 500;
}

.hd-mobile-menu-nav-list__link {
    font-size: 1.5rem;
    display: flex;
    color: var(--hd-color-neutral-text-weak);
    gap: var(--hd-space-1)
}

.hd-mobile-menu-nav-list__link:hover {
    color: var(--hd-color-accent-text);
}

.hd-mobile-menu-nav-list__link--active {
    color: var(--hd-color-accent-text);
}

.hd-mobile-menu-footer {
    padding-top: var(--hd-space-4);
    display: flex;
    border-top: var(--hd-border-size) solid var(--hd-color-neutral-border);
}

.hd-mobile-menu-footer-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: var(--hd-space-2)
}

.hd-mobile-menu-footer-link {
    display: flex;
    gap: var(--hd-space-2);
    font-size: 1rem;
    color: var(--hd-color-neutral-text);
}

.hd-header__mobile-menu-trigger-icon {
    stroke: var(--hd-color-neutral-text);
}

.hd-mobile-menu__close-icon {
    stroke: var(--hd-color-neutral-text);
}

.hd-mobile-menu-nav-list__link--disabled {
    pointer-events: none;
    text-decoration: line-through;
    color: var(--hd-color-neutral-text-weakest);
}

.hd-mobile-menu-nav-tag {
    align-self: center;
    border-radius: var(--hd-border-radius-sm);
    color: var(--hd-color-accent-text);
    background-color: var(--hd-color-accent-surface);
    font-size: 0.75rem;
    padding: 0.125rem var(--hd-space-05);
}

.hd-mobile-menu--opening {
    animation: fade-in .1s ease-in forwards;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
