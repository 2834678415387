/**
 * Do not edit directly
 * Generated on Thu, 03 Apr 2025 16:37:27 GMT
 */

:root {
  --hop-border-radius-9999: 624.9375rem;
  --hop-border-radius-4: 1.5rem;
  --hop-border-radius-3: 1rem;
  --hop-border-radius-2: 0.5rem;
  --hop-border-radius-1: 0.25rem;
  --hop-border-radius-0: 0;
  --hop-easing-expressive: cubic-bezier(0.72, -0.66, 0.15, 1.5);
  --hop-easing-focus: cubic-bezier(0.46, 0.03, 0.52, 0.96);
  --hop-easing-productive: cubic-bezier(0.22, 0.61, 0.36, 1);
  --hop-easing-duration-5: 800ms;
  --hop-easing-duration-4: 500ms;
  --hop-easing-duration-3: 300ms;
  --hop-easing-duration-2: 200ms;
  --hop-easing-duration-1: 100ms;
  --hop-line-height-1-50: 1.5;
  --hop-line-height-1-4285: 1.4285714;
  --hop-line-height-1-33: 1.3333333;
  --hop-line-height-1-25: 1.25;
  --hop-line-height-1-20: 1.2;
  --hop-line-height-1-14: 1.1428571;
  --hop-line-height-1-125: 1.125;
  --hop-font-weight-690: 690;
  --hop-font-weight-680: 680;
  --hop-font-weight-590: 590;
  --hop-font-weight-580: 580;
  --hop-font-weight-505: 505;
  --hop-font-weight-410: 410;
  --hop-font-weight-400: 400;
  --hop-font-size-480: 3rem;
  --hop-font-size-360: 2.25rem;
  --hop-font-size-320: 2rem;
  --hop-font-size-280: 1.75rem;
  --hop-font-size-240: 1.5rem;
  --hop-font-size-200: 1.25rem;
  --hop-font-size-180: 1.125rem;
  --hop-font-size-160: 1rem;
  --hop-font-size-140: 0.875rem;
  --hop-font-size-120: 0.75rem;
  --hop-font-family-tertiary: 'ABC Favorit Mono', Consolas, 'SF Mono', monospace;
  --hop-font-family-secondary: 'Inter', Helvetica, Arial, sans-serif;
  --hop-font-family-primary: 'ABC Favorit', Helvetica, Arial, sans-serif;
  --hop-shadow-lg: 0 10px 18px 8px rgba(60, 60, 60, 0.08);
  --hop-shadow-md: 0 4px 10px 4px rgba(60, 60, 60, 0.08);
  --hop-shadow-sm: 0 1px 6px 0 rgba(60, 60, 60, 0.10);
  --hop-shadow-none: none;
  --hop-samoyed: #ffffff;
  --hop-rock-900: #292929;
  --hop-rock-800: #3c3c3c;
  --hop-rock-700: #505050;
  --hop-rock-600: #636362;
  --hop-rock-500: #6c6c6b;
  --hop-rock-400: #777775;
  --hop-rock-300: #959593;
  --hop-rock-200: #b3b3b1;
  --hop-rock-100: #ccccca;
  --hop-rock-75: #e0dfdd;
  --hop-rock-50: #ecebe8;
  --hop-rock-25: #f8f6f3;
  --hop-rock-20: #fcfbfb;
  --hop-abyss: #1d1d1c;
  --hop-moss-900: #132a27;
  --hop-moss-800: #16433d;
  --hop-moss-700: #115a52;
  --hop-moss-600: #0a6f64;
  --hop-moss-500: #0c796b;
  --hop-moss-400: #188a71;
  --hop-moss-300: #47a584;
  --hop-moss-200: #7dc291;
  --hop-moss-100: #aad89d;
  --hop-moss-75: #cde8ac;
  --hop-moss-50: #e3f3b9;
  --hop-moss-25: #f4f9e9;
  --hop-amanita-900: #431a17;
  --hop-amanita-800: #6c2320;
  --hop-amanita-700: #952927;
  --hop-amanita-600: #ba2d2d;
  --hop-amanita-500: #cb2e31;
  --hop-amanita-400: #df3236;
  --hop-amanita-300: #fa4d59;
  --hop-amanita-200: #ff8e8e;
  --hop-amanita-100: #ffbcb7;
  --hop-amanita-75: #ffd6d3;
  --hop-amanita-50: #fde6e5;
  --hop-amanita-25: #fdf6f6;
  --hop-koi-900: #451a02;
  --hop-koi-800: #692803;
  --hop-koi-700: #8c3504;
  --hop-koi-600: #ab4104;
  --hop-koi-500: #ba4705;
  --hop-koi-400: #c95109;
  --hop-koi-300: #e57723;
  --hop-koi-200: #ff9b3f;
  --hop-koi-100: #ffbf92;
  --hop-koi-75: #ffd8be;
  --hop-koi-50: #ffe8d3;
  --hop-koi-25: #fff5e9;
  --hop-sunken-treasure-900: #2f250d;
  --hop-sunken-treasure-800: #4b390f;
  --hop-sunken-treasure-700: #654c0d;
  --hop-sunken-treasure-600: #7e5e0a;
  --hop-sunken-treasure-500: #8b6609;
  --hop-sunken-treasure-400: #996f08;
  --hop-sunken-treasure-300: #c28b12;
  --hop-sunken-treasure-200: #e2a934;
  --hop-sunken-treasure-100: #eac96d;
  --hop-sunken-treasure-75: #f7e694;
  --hop-sunken-treasure-50: #fff2b8;
  --hop-sunken-treasure-25: #fff8d6;
  --hop-toad-900: #2a2620;
  --hop-toad-800: #433b31;
  --hop-toad-700: #594f41;
  --hop-toad-600: #6e6151;
  --hop-toad-500: #776a59;
  --hop-toad-400: #837463;
  --hop-toad-300: #a19382;
  --hop-toad-200: #bdb1a3;
  --hop-toad-100: #d4cbc0;
  --hop-toad-75: #e5ded6;
  --hop-toad-50: #f0eae3;
  --hop-toad-25: #fef6ef;
  --hop-fog-900: #20282a;
  --hop-fog-800: #313e43;
  --hop-fog-700: #40535a;
  --hop-fog-600: #4e6770;
  --hop-fog-500: #557079;
  --hop-fog-400: #5e7b84;
  --hop-fog-300: #7c9aa3;
  --hop-fog-200: #9cb7be;
  --hop-fog-100: #bad0d5;
  --hop-fog-75: #d2e3e7;
  --hop-fog-50: #e1eef1;
  --hop-fog-25: #f2f8fa;
  --hop-sapphire-900: #152450;
  --hop-sapphire-800: #1b3587;
  --hop-sapphire-700: #2040c7;
  --hop-sapphire-600: #2a43e8;
  --hop-sapphire-500: #3b57ff;
  --hop-sapphire-400: #4767fe;
  --hop-sapphire-300: #6c8ffd;
  --hop-sapphire-200: #95b1ff;
  --hop-sapphire-100: #b9cbff;
  --hop-sapphire-75: #d6e0ff;
  --hop-sapphire-50: #e6ebff;
  --hop-sapphire-25: #f5f6ff;
  --hop-orchid-bloom-900: #1e1c5d;
  --hop-orchid-bloom-800: #322b8d;
  --hop-orchid-bloom-700: #433fac;
  --hop-orchid-bloom-600: #5454be;
  --hop-orchid-bloom-500: #5f61c5;
  --hop-orchid-bloom-400: #6b6ecc;
  --hop-orchid-bloom-300: #8d91dc;
  --hop-orchid-bloom-200: #aeb3e8;
  --hop-orchid-bloom-100: #c8caf0;
  --hop-orchid-bloom-75: #ddddf7;
  --hop-orchid-bloom-50: #eae9fb;
  --hop-orchid-bloom-25: #f6f5ff;
  --hop-quetzal-900: #002d1c;
  --hop-quetzal-800: #00452d;
  --hop-quetzal-700: #055c41;
  --hop-quetzal-600: #206f54;
  --hop-quetzal-500: #2b795e;
  --hop-quetzal-400: #38836a;
  --hop-quetzal-300: #5da18c;
  --hop-quetzal-200: #83beaf;
  --hop-quetzal-100: #a3d6cb;
  --hop-quetzal-75: #bde8e1;
  --hop-quetzal-50: #cff4ef;
  --hop-quetzal-25: #ddfdf9;
  --hop-coastal-900: #00274b;
  --hop-coastal-800: #003d70;
  --hop-coastal-700: #0a538b;
  --hop-coastal-600: #23669f;
  --hop-coastal-500: #2e70a8;
  --hop-coastal-400: #3a7bb2;
  --hop-coastal-300: #5d9acd;
  --hop-coastal-200: #81b9e4;
  --hop-coastal-100: #9fd2f7;
  --hop-coastal-75: #bae6ff;
  --hop-coastal-50: #d9efff;
  --hop-coastal-25: #f0f8ff;
  --hop-space-1280: 8rem;
  --hop-space-960: 6rem;
  --hop-space-800: 5rem;
  --hop-space-640: 4rem;
  --hop-space-480: 3rem;
  --hop-space-400: 2.5rem;
  --hop-space-320: 2rem;
  --hop-space-240: 1.5rem;
  --hop-space-160: 1rem;
  --hop-space-80: 0.5rem;
  --hop-space-40: 0.25rem;
  --hop-space-20: 0.125rem;
  --hop-space-10: 0.0625rem;
  --hop-space-0: 0;
  --hop-overline-bottom-offset: -0.25rem;
  --hop-overline-top-offset: -0.25rem;
  --hop-heading-xs-medium-bottom-offset: -0.25rem;
  --hop-heading-xs-medium-top-offset: -0.4166rem;
  --hop-heading-xs-bottom-offset: -0.25rem;
  --hop-heading-xs-top-offset: -0.4166rem;
  --hop-heading-sm-bottom-offset: -0.1875rem;
  --hop-heading-sm-top-offset: -0.375rem;
  --hop-heading-md-bottom-offset: -0.125rem;
  --hop-heading-md-top-offset: -0.3333rem;
  --hop-heading-lg-bottom-offset: -0.3125rem;
  --hop-heading-lg-top-offset: -0.5625rem;
  --hop-heading-xl-bottom-offset: -0.1875rem;
  --hop-heading-xl-top-offset: -0.5rem;
  --hop-heading-2xl-bottom-offset: -0.3334rem;
  --hop-heading-2xl-top-offset: -0.6667rem;
  --hop-heading-3xl-bottom-offset: -0.5625rem;
  --hop-heading-3xl-top-offset: -0.9375rem;
  --hop-dataviz-categorical-6colorgroup-option4-category6: #fbe997;
  --hop-dataviz-categorical-6colorgroup-option4-category5: #bfdca9;
  --hop-dataviz-categorical-6colorgroup-option4-category4: #c7ebff;
  --hop-dataviz-categorical-6colorgroup-option4-category3: #ffbf92;
  --hop-dataviz-categorical-6colorgroup-option4-category2: #a0b8fa;
  --hop-dataviz-categorical-6colorgroup-option4-category1: #fbbdcf;
  --hop-dataviz-categorical-6colorgroup-option3-category6: #fbbdcf;
  --hop-dataviz-categorical-6colorgroup-option3-category5: #2e70a8;
  --hop-dataviz-categorical-6colorgroup-option3-category4: #a7e6dc;
  --hop-dataviz-categorical-6colorgroup-option3-category3: #e8ddd0;
  --hop-dataviz-categorical-6colorgroup-option3-category2: #aecdd5;
  --hop-dataviz-categorical-6colorgroup-option3-category1: #b6bead;
  --hop-dataviz-categorical-6colorgroup-option2-category6: #d2cdf8;
  --hop-dataviz-categorical-6colorgroup-option2-category5: #a7e6dc;
  --hop-dataviz-categorical-6colorgroup-option2-category4: #ff9b3f;
  --hop-dataviz-categorical-6colorgroup-option2-category3: #69bfa0;
  --hop-dataviz-categorical-6colorgroup-option2-category2: #fbe997;
  --hop-dataviz-categorical-6colorgroup-option2-category1: #2e70a8;
  --hop-dataviz-categorical-6colorgroup-option1-category6: #69bfa0;
  --hop-dataviz-categorical-6colorgroup-option1-category5: #ecd599;
  --hop-dataviz-categorical-6colorgroup-option1-category4: #fa4d59;
  --hop-dataviz-categorical-6colorgroup-option1-category3: #bfdca9;
  --hop-dataviz-categorical-6colorgroup-option1-category2: #a0b8fa;
  --hop-dataviz-categorical-6colorgroup-option1-category1: #b6bead;
  --hop-dataviz-categorical-5colorgroup-option4-category5: #b6bead;
  --hop-dataviz-categorical-5colorgroup-option4-category4: #d2cdf8;
  --hop-dataviz-categorical-5colorgroup-option4-category3: #fa4d59;
  --hop-dataviz-categorical-5colorgroup-option4-category2: #c7ebff;
  --hop-dataviz-categorical-5colorgroup-option4-category1: #69bfa0;
  --hop-dataviz-categorical-5colorgroup-option3-category5: #ffbf92;
  --hop-dataviz-categorical-5colorgroup-option3-category4: #b6bead;
  --hop-dataviz-categorical-5colorgroup-option3-category3: #aecdd5;
  --hop-dataviz-categorical-5colorgroup-option3-category2: #ecd599;
  --hop-dataviz-categorical-5colorgroup-option3-category1: #d2cdf8;
  --hop-dataviz-categorical-5colorgroup-option2-category5: #c5bef6;
  --hop-dataviz-categorical-5colorgroup-option2-category4: #fbe997;
  --hop-dataviz-categorical-5colorgroup-option2-category3: #2e70a8;
  --hop-dataviz-categorical-5colorgroup-option2-category2: #b6bead;
  --hop-dataviz-categorical-5colorgroup-option2-category1: #ff9b3f;
  --hop-dataviz-categorical-5colorgroup-option1-category5: #69bfa0;
  --hop-dataviz-categorical-5colorgroup-option1-category4: #ecd599;
  --hop-dataviz-categorical-5colorgroup-option1-category3: #bfdca9;
  --hop-dataviz-categorical-5colorgroup-option1-category2: #ff9b3f;
  --hop-dataviz-categorical-5colorgroup-option1-category1: #b6bead;
  --hop-dataviz-categorical-4colorgroup-option4-category4: #d2cdf8;
  --hop-dataviz-categorical-4colorgroup-option4-category3: #fa4d59;
  --hop-dataviz-categorical-4colorgroup-option4-category2: #c7ebff;
  --hop-dataviz-categorical-4colorgroup-option4-category1: #69bfa0;
  --hop-dataviz-categorical-4colorgroup-option3-category4: #ecd599;
  --hop-dataviz-categorical-4colorgroup-option3-category3: #2e70a8;
  --hop-dataviz-categorical-4colorgroup-option3-category2: #b6bead;
  --hop-dataviz-categorical-4colorgroup-option3-category1: #ffbf92;
  --hop-dataviz-categorical-4colorgroup-option2-category4: #fbe997;
  --hop-dataviz-categorical-4colorgroup-option2-category3: #84d0b4;
  --hop-dataviz-categorical-4colorgroup-option2-category2: #c7ebff;
  --hop-dataviz-categorical-4colorgroup-option2-category1: #b6bead;
  --hop-dataviz-categorical-4colorgroup-option1-category4: #fbbdcf;
  --hop-dataviz-categorical-4colorgroup-option1-category3: #cde8ac;
  --hop-dataviz-categorical-4colorgroup-option1-category2: #d2cdf8;
  --hop-dataviz-categorical-4colorgroup-option1-category1: #b6bead;
  --hop-dataviz-categorical-3colorgroup-option4-category3: #fbbdcf;
  --hop-dataviz-categorical-3colorgroup-option4-category2: #a0b8fa;
  --hop-dataviz-categorical-3colorgroup-option4-category1: #b6bead;
  --hop-dataviz-categorical-3colorgroup-option3-category3: #aecdd5;
  --hop-dataviz-categorical-3colorgroup-option3-category2: #fbe997;
  --hop-dataviz-categorical-3colorgroup-option3-category1: #69bfa0;
  --hop-dataviz-categorical-3colorgroup-option2-category3: #69bfa0;
  --hop-dataviz-categorical-3colorgroup-option2-category2: #a7e6dc;
  --hop-dataviz-categorical-3colorgroup-option2-category1: #ecd599;
  --hop-dataviz-categorical-3colorgroup-option1-category3: #bfdca9;
  --hop-dataviz-categorical-3colorgroup-option1-category2: #d2cdf8;
  --hop-dataviz-categorical-3colorgroup-option1-category1: #b6bead;
  --hop-dataviz-categorical-2colorgroup-option1-category1: #b6bead;
  --hop-dataviz-categorical-2colorgroup-option1-category2: #c7ebff;
  --hop-dataviz-categorical-2colorgroup-option2-category1: #fbbdcf;
  --hop-dataviz-categorical-2colorgroup-option2-category2: #fbe997;
  --hop-dataviz-categorical-2colorgroup-option3-category1: #2f48ff;
  --hop-dataviz-categorical-2colorgroup-option3-category2: #ff9b3f;
  --hop-dataviz-categorical-2colorgroup-option4-category1: #bfdca9;
  --hop-dataviz-categorical-2colorgroup-option4-category2: #6c8ffd;
  --hop-dataviz-categorical-2colorgroup-option5-category1: #a7e6dc;
  --hop-dataviz-categorical-2colorgroup-option5-category2: #a0b8fa;
  --hop-dataviz-categorical-2colorgroup-option6-category1: #ffbf92;
  --hop-dataviz-categorical-2colorgroup-option6-category2: #69bfa0;
  --hop-dataviz-categorical-sequence-category13-hover: #54b692;
  --hop-dataviz-categorical-sequence-category13: #69bfa0;
  --hop-dataviz-categorical-sequence-category12-hover: #779af8;
  --hop-dataviz-categorical-sequence-category12: #a0b8fa;
  --hop-dataviz-categorical-sequence-category11-hover: #ffac70;
  --hop-dataviz-categorical-sequence-category11: #ffbf92;
  --hop-dataviz-categorical-sequence-category10-hover: #93bdc8;
  --hop-dataviz-categorical-sequence-category10: #aecdd5;
  --hop-dataviz-categorical-sequence-category9-hover: #90e0d2;
  --hop-dataviz-categorical-sequence-category9: #a7e6dc;
  --hop-dataviz-categorical-sequence-category8-hover: #ddcebb;
  --hop-dataviz-categorical-sequence-category8: #e8ddd0;
  --hop-dataviz-categorical-sequence-category7-hover: #fae275;
  --hop-dataviz-categorical-sequence-category7: #fbe997;
  --hop-dataviz-categorical-sequence-category6-hover: #a9d08b;
  --hop-dataviz-categorical-sequence-category6: #bfdca9;
  --hop-dataviz-categorical-sequence-category5-hover: #f99fb8;
  --hop-dataviz-categorical-sequence-category5: #fbbdcf;
  --hop-dataviz-categorical-sequence-category4-hover: #a4ae98;
  --hop-dataviz-categorical-sequence-category4: #b6bead;
  --hop-dataviz-categorical-sequence-category3-hover: #bfb8f5;
  --hop-dataviz-categorical-sequence-category3: #d2cdf8;
  --hop-dataviz-categorical-sequence-category2-hover: #e6c675;
  --hop-dataviz-categorical-sequence-category2: #ecd599;
  --hop-dataviz-categorical-sequence-category1-hover: #ade2ff;
  --hop-dataviz-categorical-sequence-category1: #c7ebff;
  --hop-dataviz-diverging-sequence-2-negative10-hover: #431a17;
  --hop-dataviz-diverging-sequence-2-negative10: #6c2320;
  --hop-dataviz-diverging-sequence-2-negative9-hover: #6c2320;
  --hop-dataviz-diverging-sequence-2-negative9: #952927;
  --hop-dataviz-diverging-sequence-2-negative8-hover: #952927;
  --hop-dataviz-diverging-sequence-2-negative8: #ba2d2d;
  --hop-dataviz-diverging-sequence-2-negative7-hover: #ba2d2d;
  --hop-dataviz-diverging-sequence-2-negative7: #cb2e31;
  --hop-dataviz-diverging-sequence-2-negative6-hover: #cb2e31;
  --hop-dataviz-diverging-sequence-2-negative6: #df3236;
  --hop-dataviz-diverging-sequence-2-negative5-hover: #df3236;
  --hop-dataviz-diverging-sequence-2-negative5: #f56263;
  --hop-dataviz-diverging-sequence-2-negative4-hover: #f56263;
  --hop-dataviz-diverging-sequence-2-negative4: #ff8e8e;
  --hop-dataviz-diverging-sequence-2-negative3-hover: #ff8e8e;
  --hop-dataviz-diverging-sequence-2-negative3: #ffbcb7;
  --hop-dataviz-diverging-sequence-2-negative2-hover: #ffbcb7;
  --hop-dataviz-diverging-sequence-2-negative2: #ffd6d3;
  --hop-dataviz-diverging-sequence-2-negative1-hover: #ffd6d3;
  --hop-dataviz-diverging-sequence-2-negative1: #fde6e5;
  --hop-dataviz-diverging-sequence-2-positive1-hover: #cde8ac;
  --hop-dataviz-diverging-sequence-2-positive1: #e3f3b9;
  --hop-dataviz-diverging-sequence-2-positive2-hover: #aad89d;
  --hop-dataviz-diverging-sequence-2-positive2: #cde8ac;
  --hop-dataviz-diverging-sequence-2-positive3-hover: #7dc291;
  --hop-dataviz-diverging-sequence-2-positive3: #aad89d;
  --hop-dataviz-diverging-sequence-2-positive4-hover: #47a584;
  --hop-dataviz-diverging-sequence-2-positive4: #7dc291;
  --hop-dataviz-diverging-sequence-2-positive5-hover: #188a71;
  --hop-dataviz-diverging-sequence-2-positive5: #47a584;
  --hop-dataviz-diverging-sequence-2-positive6-hover: #0c796b;
  --hop-dataviz-diverging-sequence-2-positive6: #188a71;
  --hop-dataviz-diverging-sequence-2-positive7-hover: #0a6f64;
  --hop-dataviz-diverging-sequence-2-positive7: #0c796b;
  --hop-dataviz-diverging-sequence-2-positive8-hover: #115a52;
  --hop-dataviz-diverging-sequence-2-positive8: #0a6f64;
  --hop-dataviz-diverging-sequence-2-positive9-hover: #16433d;
  --hop-dataviz-diverging-sequence-2-positive9: #115a52;
  --hop-dataviz-diverging-sequence-2-positive10-hover: #132a27;
  --hop-dataviz-diverging-sequence-2-positive10: #16433d;
  --hop-dataviz-diverging-sequence-1-negative9-hover: #431a17;
  --hop-dataviz-diverging-sequence-1-negative9: #6c2320;
  --hop-dataviz-diverging-sequence-1-negative8-hover: #6c2320;
  --hop-dataviz-diverging-sequence-1-negative8: #952927;
  --hop-dataviz-diverging-sequence-1-negative7-hover: #952927;
  --hop-dataviz-diverging-sequence-1-negative7: #ba2d2d;
  --hop-dataviz-diverging-sequence-1-negative6-hover: #ba2d2d;
  --hop-dataviz-diverging-sequence-1-negative6: #cb2e31;
  --hop-dataviz-diverging-sequence-1-negative5-hover: #cb2e31;
  --hop-dataviz-diverging-sequence-1-negative5: #df3236;
  --hop-dataviz-diverging-sequence-1-negative4-hover: #df3236;
  --hop-dataviz-diverging-sequence-1-negative4: #f56263;
  --hop-dataviz-diverging-sequence-1-negative3-hover: #f56263;
  --hop-dataviz-diverging-sequence-1-negative3: #ff8e8e;
  --hop-dataviz-diverging-sequence-1-negative2-hover: #ff8e8e;
  --hop-dataviz-diverging-sequence-1-negative2: #ffbcb7;
  --hop-dataviz-diverging-sequence-1-negative1-hover: #ffbcb7;
  --hop-dataviz-diverging-sequence-1-negative1: #ffd8be;
  --hop-dataviz-diverging-sequence-1-neutral-hover: #eac96d;
  --hop-dataviz-diverging-sequence-1-neutral: #f7e694;
  --hop-dataviz-diverging-sequence-1-positive1-hover: #aad89d;
  --hop-dataviz-diverging-sequence-1-positive1: #cde8ac;
  --hop-dataviz-diverging-sequence-1-positive2-hover: #7dc291;
  --hop-dataviz-diverging-sequence-1-positive2: #aad89d;
  --hop-dataviz-diverging-sequence-1-positive3-hover: #47a584;
  --hop-dataviz-diverging-sequence-1-positive3: #7dc291;
  --hop-dataviz-diverging-sequence-1-positive-4-hover: #188a71;
  --hop-dataviz-diverging-sequence-1-positive4: #47a584;
  --hop-dataviz-diverging-sequence-1-positive5-hover: #0c796b;
  --hop-dataviz-diverging-sequence-1-positive5: #188a71;
  --hop-dataviz-diverging-sequence-1-positive6-hover: #0a6f64;
  --hop-dataviz-diverging-sequence-1-positive6: #0c796b;
  --hop-dataviz-diverging-sequence-1-positive7-hover: #115a52;
  --hop-dataviz-diverging-sequence-1-positive7: #0a6f64;
  --hop-dataviz-diverging-sequence-1-positive8-hover: #16433d;
  --hop-dataviz-diverging-sequence-1-positive8: #115a52;
  --hop-dataviz-diverging-sequence-1-positive9-hover: #132a27;
  --hop-dataviz-diverging-sequence-1-positive9: #16433d;
  --hop-dataviz-monochromatic-negative-900-hover: #2d100d;
  --hop-dataviz-monochromatic-negative-800-hover: #431a17;
  --hop-dataviz-monochromatic-negative-700-hover: #6c2320;
  --hop-dataviz-monochromatic-negative-600-hover: #952927;
  --hop-dataviz-monochromatic-negative-500-hover: #ba2d2d;
  --hop-dataviz-monochromatic-negative-400-hover: #cb2e31;
  --hop-dataviz-monochromatic-negative-300-hover: #df3236;
  --hop-dataviz-monochromatic-negative-200-hover: #f56263;
  --hop-dataviz-monochromatic-negative-100-hover: #ff8e8e;
  --hop-dataviz-monochromatic-negative-75-hover: #ffbcb7;
  --hop-dataviz-monochromatic-negative-50-hover: #ffd6d3;
  --hop-dataviz-monochromatic-negative-25-hover: #fde6e5;
  --hop-dataviz-monochromatic-negative-900: #431a17;
  --hop-dataviz-monochromatic-negative-800: #6c2320;
  --hop-dataviz-monochromatic-negative-700: #952927;
  --hop-dataviz-monochromatic-negative-600: #ba2d2d;
  --hop-dataviz-monochromatic-negative-500: #cb2e31;
  --hop-dataviz-monochromatic-negative-400: #df3236;
  --hop-dataviz-monochromatic-negative-300: #f56263;
  --hop-dataviz-monochromatic-negative-200: #ff8e8e;
  --hop-dataviz-monochromatic-negative-100: #ffbcb7;
  --hop-dataviz-monochromatic-negative-75: #ffd6d3;
  --hop-dataviz-monochromatic-negative-50: #fde6e5;
  --hop-dataviz-monochromatic-negative-25: #fdf6f6;
  --hop-dataviz-monochromatic-positive-900-hover: #0a1716;
  --hop-dataviz-monochromatic-positive-800-hover: #132a27;
  --hop-dataviz-monochromatic-positive-700-hover: #16433d;
  --hop-dataviz-monochromatic-positive-600-hover: #115a52;
  --hop-dataviz-monochromatic-positive-500-hover: #0a6f64;
  --hop-dataviz-monochromatic-positive-400-hover: #0c796b;
  --hop-dataviz-monochromatic-positive-300-hover: #188a71;
  --hop-dataviz-monochromatic-positive-200-hover: #47a584;
  --hop-dataviz-monochromatic-positive-100-hover: #7dc291;
  --hop-dataviz-monochromatic-positive-75-hover: #aad89d;
  --hop-dataviz-monochromatic-positive-50-hover: #cde8ac;
  --hop-dataviz-monochromatic-positive-25-hover: #e3f3b9;
  --hop-dataviz-monochromatic-positive-900: #132a27;
  --hop-dataviz-monochromatic-positive-800: #16433d;
  --hop-dataviz-monochromatic-positive-700: #115a52;
  --hop-dataviz-monochromatic-positive-600: #0a6f64;
  --hop-dataviz-monochromatic-positive-500: #0c796b;
  --hop-dataviz-monochromatic-positive-400: #188a71;
  --hop-dataviz-monochromatic-positive-300: #47a584;
  --hop-dataviz-monochromatic-positive-200: #7dc291;
  --hop-dataviz-monochromatic-positive-100: #aad89d;
  --hop-dataviz-monochromatic-positive-75: #cde8ac;
  --hop-dataviz-monochromatic-positive-50: #e3f3b9;
  --hop-dataviz-monochromatic-positive-25: #f4f9e9;
  --hop-dataviz-monochromatic-primary-900-hover: #0b173a;
  --hop-dataviz-monochromatic-primary-800-hover: #152450;
  --hop-dataviz-monochromatic-primary-700-hover: #1b3587;
  --hop-dataviz-monochromatic-primary-600-hover: #2040c7;
  --hop-dataviz-monochromatic-primary-500-hover: #2a43e8;
  --hop-dataviz-monochromatic-primary-400-hover: #3b57ff;
  --hop-dataviz-monochromatic-primary-300-hover: #4767fe;
  --hop-dataviz-monochromatic-primary-200-hover: #6c8ffd;
  --hop-dataviz-monochromatic-primary-100-hover: #95b1ff;
  --hop-dataviz-monochromatic-primary-75-hover: #b9cbff;
  --hop-dataviz-monochromatic-primary-50-hover: #d6e0ff;
  --hop-dataviz-monochromatic-primary-25-hover: #e6ebff;
  --hop-dataviz-monochromatic-primary-900: #152450;
  --hop-dataviz-monochromatic-primary-800: #1b3587;
  --hop-dataviz-monochromatic-primary-700: #2040c7;
  --hop-dataviz-monochromatic-primary-600: #2a43e8;
  --hop-dataviz-monochromatic-primary-500: #3b57ff;
  --hop-dataviz-monochromatic-primary-400: #4767fe;
  --hop-dataviz-monochromatic-primary-300: #6c8ffd;
  --hop-dataviz-monochromatic-primary-200: #95b1ff;
  --hop-dataviz-monochromatic-primary-100: #b9cbff;
  --hop-dataviz-monochromatic-primary-75: #d6e0ff;
  --hop-dataviz-monochromatic-primary-50: #e6ebff;
  --hop-dataviz-monochromatic-primary-25: #f5f6ff;
  --hop-space-inline-xl: var(--hop-space-320);
  --hop-space-inline-lg: var(--hop-space-240);
  --hop-space-inline-md: var(--hop-space-160);
  --hop-space-inline-sm: var(--hop-space-80);
  --hop-space-inline-xs: var(--hop-space-40);
  --hop-space-stack-xl: var(--hop-space-320);
  --hop-space-stack-lg: var(--hop-space-240);
  --hop-space-stack-md: var(--hop-space-160);
  --hop-space-stack-sm: var(--hop-space-80);
  --hop-space-stack-xs: var(--hop-space-40);
  --hop-space-inset-stretch-lg: var(--hop-space-480) var(--hop-space-240);
  --hop-space-inset-stretch-md: var(--hop-space-240) var(--hop-space-160);
  --hop-space-inset-stretch-sm: var(--hop-space-160) var(--hop-space-80);
  --hop-space-inset-squish-lg: var(--hop-space-160) var(--hop-space-320);
  --hop-space-inset-squish-md: var(--hop-space-80) var(--hop-space-160);
  --hop-space-inset-squish-sm: var(--hop-space-40) var(--hop-space-80);
  --hop-space-inset-xl: var(--hop-space-320);
  --hop-space-inset-lg: var(--hop-space-240);
  --hop-space-inset-md: var(--hop-space-160);
  --hop-space-inset-sm: var(--hop-space-80);
  --hop-space-inset-xs: var(--hop-space-40);
  --hop-shape-rounded-sm: var(--hop-border-radius-1);
  --hop-shape-rounded-md: var(--hop-border-radius-2);
  --hop-shape-rounded-lg: var(--hop-border-radius-3);
  --hop-shape-pill: var(--hop-border-radius-4);
  --hop-shape-circle: var(--hop-border-radius-9999);
  --hop-accent-sm-line-height: var(--hop-line-height-1-33);
  --hop-accent-sm-font-weight: var(--hop-font-weight-580);
  --hop-accent-sm-font-size: var(--hop-font-size-180);
  --hop-accent-sm-font-family: var(--hop-font-family-primary);
  --hop-accent-lg-line-height: var(--hop-line-height-1-125);
  --hop-accent-lg-font-weight: var(--hop-font-weight-580);
  --hop-accent-lg-font-size: var(--hop-font-size-480);
  --hop-accent-lg-font-family: var(--hop-font-family-primary);
  --hop-body-xs-underline-line-height: var(--hop-line-height-1-33);
  --hop-body-xs-underline-font-weight: var(--hop-font-weight-505);
  --hop-body-xs-underline-font-size: var(--hop-font-size-120);
  --hop-body-xs-underline-font-family: var(--hop-font-family-secondary);
  --hop-body-xs-bold-line-height: var(--hop-line-height-1-33);
  --hop-body-xs-bold-font-weight: var(--hop-font-weight-690);
  --hop-body-xs-bold-font-size: var(--hop-font-size-120);
  --hop-body-xs-bold-font-family: var(--hop-font-family-secondary);
  --hop-body-xs-semibold-line-height: var(--hop-line-height-1-33);
  --hop-body-xs-semibold-font-weight: var(--hop-font-weight-590);
  --hop-body-xs-semibold-font-size: var(--hop-font-size-120);
  --hop-body-xs-semibold-font-family: var(--hop-font-family-secondary);
  --hop-body-xs-medium-line-height: var(--hop-line-height-1-33);
  --hop-body-xs-medium-font-weight: var(--hop-font-weight-505);
  --hop-body-xs-medium-font-size: var(--hop-font-size-120);
  --hop-body-xs-medium-font-family: var(--hop-font-family-secondary);
  --hop-body-xs-line-height: var(--hop-line-height-1-33);
  --hop-body-xs-font-weight: var(--hop-font-weight-410);
  --hop-body-xs-font-size: var(--hop-font-size-120);
  --hop-body-xs-font-family: var(--hop-font-family-secondary);
  --hop-body-sm-underline-line-height: var(--hop-line-height-1-4285);
  --hop-body-sm-underline-font-weight: var(--hop-font-weight-505);
  --hop-body-sm-underline-font-size: var(--hop-font-size-140);
  --hop-body-sm-underline-font-family: var(--hop-font-family-secondary);
  --hop-body-sm-bold-line-height: var(--hop-line-height-1-4285);
  --hop-body-sm-bold-font-weight: var(--hop-font-weight-690);
  --hop-body-sm-bold-font-size: var(--hop-font-size-140);
  --hop-body-sm-bold-font-family: var(--hop-font-family-secondary);
  --hop-body-sm-semibold-line-height: var(--hop-line-height-1-4285);
  --hop-body-sm-semibold-font-weight: var(--hop-font-weight-590);
  --hop-body-sm-semibold-font-size: var(--hop-font-size-140);
  --hop-body-sm-semibold-font-family: var(--hop-font-family-secondary);
  --hop-body-sm-medium-line-height: var(--hop-line-height-1-4285);
  --hop-body-sm-medium-font-weight: var(--hop-font-weight-505);
  --hop-body-sm-medium-font-size: var(--hop-font-size-140);
  --hop-body-sm-medium-font-family: var(--hop-font-family-secondary);
  --hop-body-sm-line-height: var(--hop-line-height-1-4285);
  --hop-body-sm-font-weight: var(--hop-font-weight-410);
  --hop-body-sm-font-size: var(--hop-font-size-140);
  --hop-body-sm-font-family: var(--hop-font-family-secondary);
  --hop-body-md-underline-line-height: var(--hop-line-height-1-50);
  --hop-body-md-underline-font-weight: var(--hop-font-weight-410);
  --hop-body-md-underline-font-size: var(--hop-font-size-160);
  --hop-body-md-underline-font-family: var(--hop-font-family-secondary);
  --hop-body-md-bold-line-height: var(--hop-line-height-1-50);
  --hop-body-md-bold-font-weight: var(--hop-font-weight-690);
  --hop-body-md-bold-font-size: var(--hop-font-size-160);
  --hop-body-md-bold-font-family: var(--hop-font-family-secondary);
  --hop-body-md-semibold-line-height: var(--hop-line-height-1-50);
  --hop-body-md-semibold-font-weight: var(--hop-font-weight-590);
  --hop-body-md-semibold-font-size: var(--hop-font-size-160);
  --hop-body-md-semibold-font-family: var(--hop-font-family-secondary);
  --hop-body-md-medium-line-height: var(--hop-line-height-1-50);
  --hop-body-md-medium-font-weight: var(--hop-font-weight-505);
  --hop-body-md-medium-font-size: var(--hop-font-size-160);
  --hop-body-md-medium-font-family: var(--hop-font-family-secondary);
  --hop-body-md-line-height: var(--hop-line-height-1-50);
  --hop-body-md-font-weight: var(--hop-font-weight-410);
  --hop-body-md-font-size: var(--hop-font-size-160);
  --hop-body-md-font-family: var(--hop-font-family-secondary);
  --hop-body-lg-underline-line-height: var(--hop-line-height-1-33);
  --hop-body-lg-underline-font-weight: var(--hop-font-weight-505);
  --hop-body-lg-underline-font-size: var(--hop-font-size-180);
  --hop-body-lg-underline-font-family: var(--hop-font-family-secondary);
  --hop-body-lg-bold-line-height: var(--hop-line-height-1-33);
  --hop-body-lg-bold-font-weight: var(--hop-font-weight-690);
  --hop-body-lg-bold-font-size: var(--hop-font-size-180);
  --hop-body-lg-bold-font-family: var(--hop-font-family-secondary);
  --hop-body-lg-semibold-line-height: var(--hop-line-height-1-33);
  --hop-body-lg-semibold-font-weight: var(--hop-font-weight-590);
  --hop-body-lg-semibold-font-size: var(--hop-font-size-180);
  --hop-body-lg-semibold-font-family: var(--hop-font-family-secondary);
  --hop-body-lg-medium-line-height: var(--hop-line-height-1-33);
  --hop-body-lg-medium-font-weight: var(--hop-font-weight-505);
  --hop-body-lg-medium-font-size: var(--hop-font-size-180);
  --hop-body-lg-medium-font-family: var(--hop-font-family-secondary);
  --hop-body-lg-line-height: var(--hop-line-height-1-33);
  --hop-body-lg-font-weight: var(--hop-font-weight-410);
  --hop-body-lg-font-size: var(--hop-font-size-180);
  --hop-body-lg-font-family: var(--hop-font-family-secondary);
  --hop-body-xl-line-height: var(--hop-line-height-1-14);
  --hop-body-xl-font-weight: var(--hop-font-weight-410);
  --hop-body-xl-font-size: var(--hop-font-size-280);
  --hop-body-xl-font-family: var(--hop-font-family-secondary);
  --hop-body-2xl-line-height: var(--hop-line-height-1-125);
  --hop-body-2xl-font-weight: var(--hop-font-weight-410);
  --hop-body-2xl-font-size: var(--hop-font-size-320);
  --hop-body-2xl-font-family: var(--hop-font-family-secondary);
  --hop-overline-line-height: var(--hop-line-height-1-33);
  --hop-overline-font-weight: var(--hop-font-weight-400);
  --hop-overline-font-size: var(--hop-font-size-120);
  --hop-overline-font-family: var(--hop-font-family-tertiary);
  --hop-heading-xs-medium-line-height: var(--hop-line-height-1-50);
  --hop-heading-xs-medium-font-weight: var(--hop-font-weight-580);
  --hop-heading-xs-medium-font-size: var(--hop-font-size-160);
  --hop-heading-xs-medium-font-family: var(--hop-font-family-primary);
  --hop-heading-xs-line-height: var(--hop-line-height-1-50);
  --hop-heading-xs-font-weight: var(--hop-font-weight-410);
  --hop-heading-xs-font-size: var(--hop-font-size-160);
  --hop-heading-xs-font-family: var(--hop-font-family-primary);
  --hop-heading-sm-line-height: var(--hop-line-height-1-33);
  --hop-heading-sm-font-weight: var(--hop-font-weight-580);
  --hop-heading-sm-font-size: var(--hop-font-size-180);
  --hop-heading-sm-font-family: var(--hop-font-family-primary);
  --hop-heading-md-line-height: var(--hop-line-height-1-20);
  --hop-heading-md-font-weight: var(--hop-font-weight-580);
  --hop-heading-md-font-size: var(--hop-font-size-200);
  --hop-heading-md-font-family: var(--hop-font-family-primary);
  --hop-heading-lg-line-height: var(--hop-line-height-1-33);
  --hop-heading-lg-font-weight: var(--hop-font-weight-680);
  --hop-heading-lg-font-size: var(--hop-font-size-240);
  --hop-heading-lg-font-family: var(--hop-font-family-primary);
  --hop-heading-xl-line-height: var(--hop-line-height-1-14);
  --hop-heading-xl-font-weight: var(--hop-font-weight-680);
  --hop-heading-xl-font-size: var(--hop-font-size-280);
  --hop-heading-xl-font-family: var(--hop-font-family-primary);
  --hop-heading-2xl-line-height: var(--hop-line-height-1-25);
  --hop-heading-2xl-font-weight: var(--hop-font-weight-580);
  --hop-heading-2xl-font-size: var(--hop-font-size-320);
  --hop-heading-2xl-font-family: var(--hop-font-family-primary);
  --hop-heading-3xl-line-height: var(--hop-line-height-1-33);
  --hop-heading-3xl-font-weight: var(--hop-font-weight-680);
  --hop-heading-3xl-font-size: var(--hop-font-size-360);
  --hop-heading-3xl-font-family: var(--hop-font-family-primary);
  --hop-elevation-floating: var(--hop-shadow-lg);
  --hop-elevation-lifted: var(--hop-shadow-md);
  --hop-elevation-raised: var(--hop-shadow-sm);
  --hop-elevation-none: var(--hop-shadow-none);
  --hop-dataviz-text-ondark: var(--hop-samoyed);
  --hop-dataviz-text-onlight: var(--hop-rock-800);
  --hop-dataviz-unavailable-strong: var(--hop-rock-100);
  --hop-dataviz-unavailable-weak: var(--hop-rock-50);
  --hop-dataviz-unavailable: var(--hop-rock-75);
  --hop-warning-text-weak: var(--hop-koi-300);
  --hop-warning-text: var(--hop-koi-700);
  --hop-warning-surface-weak: var(--hop-koi-50);
  --hop-warning-surface-strong: var(--hop-koi-100);
  --hop-warning-surface: var(--hop-koi-25);
  --hop-warning-icon-weak: var(--hop-koi-300);
  --hop-warning-icon-weakest: var(--hop-koi-100);
  --hop-warning-icon: var(--hop-koi-700);
  --hop-warning-border: var(--hop-koi-100);
  --hop-success-text-weak: var(--hop-moss-300);
  --hop-success-text-hover: var(--hop-moss-800);
  --hop-success-text: var(--hop-moss-700);
  --hop-success-surface-weak: var(--hop-moss-50);
  --hop-success-surface-strong: var(--hop-moss-100);
  --hop-success-surface: var(--hop-moss-25);
  --hop-success-icon-weak: var(--hop-moss-300);
  --hop-success-icon-weakest: var(--hop-moss-100);
  --hop-success-icon: var(--hop-moss-700);
  --hop-success-border: var(--hop-moss-100);
  --hop-status-progress-text-selected: var(--hop-sapphire-500);
  --hop-status-progress-text-press: var(--hop-sapphire-800);
  --hop-status-progress-text-hover: var(--hop-sapphire-700);
  --hop-status-progress-text-disabled: var(--hop-sapphire-200);
  --hop-status-progress-text: var(--hop-sapphire-600);
  --hop-status-progress-surface-strong: var(--hop-sapphire-200);
  --hop-status-progress-surface-selected: var(--hop-sapphire-50);
  --hop-status-progress-surface-press: var(--hop-sapphire-100);
  --hop-status-progress-surface-hover: var(--hop-sapphire-75);
  --hop-status-progress-surface-disabled: var(--hop-sapphire-25);
  --hop-status-progress-surface: var(--hop-sapphire-50);
  --hop-status-progress-icon-selected: var(--hop-sapphire-500);
  --hop-status-progress-icon-press: var(--hop-sapphire-800);
  --hop-status-progress-icon-hover: var(--hop-sapphire-700);
  --hop-status-progress-icon-disabled: var(--hop-sapphire-200);
  --hop-status-progress-icon: var(--hop-sapphire-600);
  --hop-status-progress-border-selected: var(--hop-sapphire-400);
  --hop-status-progress-border-press: var(--hop-sapphire-500);
  --hop-status-progress-border-hover: var(--hop-sapphire-400);
  --hop-status-progress-border-disabled: var(--hop-sapphire-50);
  --hop-status-progress-border: var(--hop-sapphire-300);
  --hop-status-positive-text-selected: var(--hop-moss-700);
  --hop-status-positive-text-press: var(--hop-moss-800);
  --hop-status-positive-text-hover: var(--hop-moss-700);
  --hop-status-positive-text-disabled: var(--hop-moss-200);
  --hop-status-positive-text: var(--hop-moss-600);
  --hop-status-positive-surface-strong: var(--hop-moss-200);
  --hop-status-positive-surface-selected: var(--hop-moss-50);
  --hop-status-positive-surface-press: var(--hop-moss-100);
  --hop-status-positive-surface-hover: var(--hop-moss-75);
  --hop-status-positive-surface-disabled: var(--hop-moss-25);
  --hop-status-positive-surface: var(--hop-moss-50);
  --hop-status-positive-icon-selected: var(--hop-moss-700);
  --hop-status-positive-icon-press: var(--hop-moss-800);
  --hop-status-positive-icon-hover: var(--hop-moss-700);
  --hop-status-positive-icon-disabled: var(--hop-moss-200);
  --hop-status-positive-icon: var(--hop-moss-600);
  --hop-status-positive-border-selected: var(--hop-moss-700);
  --hop-status-positive-border-press: var(--hop-moss-500);
  --hop-status-positive-border-hover: var(--hop-moss-400);
  --hop-status-positive-border-disabled: var(--hop-moss-50);
  --hop-status-positive-border: var(--hop-moss-300);
  --hop-status-option6-text-selected: var(--hop-sunken-treasure-700);
  --hop-status-option6-text-press: var(--hop-sunken-treasure-800);
  --hop-status-option6-text-hover: var(--hop-sunken-treasure-700);
  --hop-status-option6-text-disabled: var(--hop-sunken-treasure-200);
  --hop-status-option6-text: var(--hop-sunken-treasure-600);
  --hop-status-option6-surface-strong: var(--hop-sunken-treasure-200);
  --hop-status-option6-surface-selected: var(--hop-sunken-treasure-50);
  --hop-status-option6-surface-press: var(--hop-sunken-treasure-100);
  --hop-status-option6-surface-hover: var(--hop-sunken-treasure-50);
  --hop-status-option6-surface-disabled: var(--hop-sunken-treasure-25);
  --hop-status-option6-surface: var(--hop-sunken-treasure-25);
  --hop-status-option6-icon-selected: var(--hop-sunken-treasure-700);
  --hop-status-option6-icon-press: var(--hop-sunken-treasure-800);
  --hop-status-option6-icon-hover: var(--hop-sunken-treasure-700);
  --hop-status-option6-icon-disabled: var(--hop-sunken-treasure-200);
  --hop-status-option6-icon: var(--hop-sunken-treasure-600);
  --hop-status-option6-border-selected: var(--hop-sunken-treasure-700);
  --hop-status-option6-border-press: var(--hop-sunken-treasure-500);
  --hop-status-option6-border-hover: var(--hop-sunken-treasure-400);
  --hop-status-option6-border-disabled: var(--hop-sunken-treasure-50);
  --hop-status-option6-border: var(--hop-sunken-treasure-300);
  --hop-status-option5-text-selected: var(--hop-toad-700);
  --hop-status-option5-text-press: var(--hop-toad-800);
  --hop-status-option5-text-hover: var(--hop-toad-700);
  --hop-status-option5-text-disabled: var(--hop-toad-200);
  --hop-status-option5-text: var(--hop-toad-600);
  --hop-status-option5-surface-strong: var(--hop-toad-200);
  --hop-status-option5-surface-selected: var(--hop-toad-50);
  --hop-status-option5-surface-press: var(--hop-toad-100);
  --hop-status-option5-surface-hover: var(--hop-toad-75);
  --hop-status-option5-surface-disabled: var(--hop-toad-25);
  --hop-status-option5-surface: var(--hop-toad-50);
  --hop-status-option5-icon-selected: var(--hop-toad-700);
  --hop-status-option5-icon-press: var(--hop-toad-800);
  --hop-status-option5-icon-hover: var(--hop-toad-700);
  --hop-status-option5-icon-disabled: var(--hop-toad-200);
  --hop-status-option5-icon: var(--hop-toad-600);
  --hop-status-option5-border-selected: var(--hop-toad-700);
  --hop-status-option5-border-press: var(--hop-toad-500);
  --hop-status-option5-border-hover: var(--hop-toad-400);
  --hop-status-option5-border-disabled: var(--hop-toad-50);
  --hop-status-option5-border: var(--hop-toad-300);
  --hop-status-option4-text-selected: var(--hop-fog-700);
  --hop-status-option4-text-press: var(--hop-fog-800);
  --hop-status-option4-text-hover: var(--hop-fog-700);
  --hop-status-option4-text-disabled: var(--hop-fog-200);
  --hop-status-option4-text: var(--hop-fog-600);
  --hop-status-option4-surface-strong: var(--hop-fog-200);
  --hop-status-option4-surface-selected: var(--hop-fog-50);
  --hop-status-option4-surface-press: var(--hop-fog-100);
  --hop-status-option4-surface-hover: var(--hop-fog-75);
  --hop-status-option4-surface-disabled: var(--hop-fog-25);
  --hop-status-option4-surface: var(--hop-fog-50);
  --hop-status-option4-icon-selected: var(--hop-fog-700);
  --hop-status-option4-icon-press: var(--hop-fog-800);
  --hop-status-option4-icon-hover: var(--hop-fog-700);
  --hop-status-option4-icon-disabled: var(--hop-fog-200);
  --hop-status-option4-icon: var(--hop-fog-600);
  --hop-status-option4-border-selected: var(--hop-fog-700);
  --hop-status-option4-border-press: var(--hop-fog-500);
  --hop-status-option4-border-hover: var(--hop-fog-400);
  --hop-status-option4-border-disabled: var(--hop-fog-50);
  --hop-status-option4-border: var(--hop-fog-300);
  --hop-status-option3-text-selected: var(--hop-quetzal-700);
  --hop-status-option3-text-press: var(--hop-quetzal-800);
  --hop-status-option3-text-hover: var(--hop-quetzal-700);
  --hop-status-option3-text-disabled: var(--hop-quetzal-200);
  --hop-status-option3-text: var(--hop-quetzal-600);
  --hop-status-option3-surface-strong: var(--hop-quetzal-200);
  --hop-status-option3-surface-selected: var(--hop-quetzal-700);
  --hop-status-option3-surface-press: var(--hop-quetzal-100);
  --hop-status-option3-surface-hover: var(--hop-quetzal-75);
  --hop-status-option3-surface-disabled: var(--hop-quetzal-25);
  --hop-status-option3-surface: var(--hop-quetzal-50);
  --hop-status-option3-icon-selected: var(--hop-quetzal-700);
  --hop-status-option3-icon-press: var(--hop-quetzal-800);
  --hop-status-option3-icon-hover: var(--hop-quetzal-700);
  --hop-status-option3-icon-disabled: var(--hop-quetzal-200);
  --hop-status-option3-icon: var(--hop-quetzal-600);
  --hop-status-option3-border-selected: var(--hop-quetzal-50);
  --hop-status-option3-border-press: var(--hop-quetzal-500);
  --hop-status-option3-border-hover: var(--hop-quetzal-400);
  --hop-status-option3-border-disabled: var(--hop-quetzal-50);
  --hop-status-option3-border: var(--hop-quetzal-300);
  --hop-status-option2-text-selected: var(--hop-orchid-bloom-700);
  --hop-status-option2-text-press: var(--hop-orchid-bloom-800);
  --hop-status-option2-text-hover: var(--hop-orchid-bloom-700);
  --hop-status-option2-text-disabled: var(--hop-orchid-bloom-200);
  --hop-status-option2-text: var(--hop-orchid-bloom-600);
  --hop-status-option2-surface-strong: var(--hop-orchid-bloom-200);
  --hop-status-option2-surface-selected: var(--hop-orchid-bloom-50);
  --hop-status-option2-surface-press: var(--hop-orchid-bloom-100);
  --hop-status-option2-surface-hover: var(--hop-orchid-bloom-75);
  --hop-status-option2-surface-disabled: var(--hop-orchid-bloom-25);
  --hop-status-option2-surface: var(--hop-orchid-bloom-50);
  --hop-status-option2-icon-selected: var(--hop-orchid-bloom-700);
  --hop-status-option2-icon-press: var(--hop-orchid-bloom-800);
  --hop-status-option2-icon-hover: var(--hop-orchid-bloom-700);
  --hop-status-option2-icon-disabled: var(--hop-orchid-bloom-200);
  --hop-status-option2-icon: var(--hop-orchid-bloom-600);
  --hop-status-option2-border-selected: var(--hop-orchid-bloom-700);
  --hop-status-option2-border-press: var(--hop-orchid-bloom-500);
  --hop-status-option2-border-hover: var(--hop-orchid-bloom-400);
  --hop-status-option2-border-disabled: var(--hop-orchid-bloom-50);
  --hop-status-option2-border: var(--hop-orchid-bloom-300);
  --hop-status-option1-text-selected: var(--hop-coastal-700);
  --hop-status-option1-text-press: var(--hop-coastal-800);
  --hop-status-option1-text-hover: var(--hop-coastal-700);
  --hop-status-option1-text-disabled: var(--hop-coastal-200);
  --hop-status-option1-text: var(--hop-coastal-600);
  --hop-status-option1-surface-strong: var(--hop-coastal-200);
  --hop-status-option1-surface-selected: var(--hop-coastal-50);
  --hop-status-option1-surface-press: var(--hop-coastal-100);
  --hop-status-option1-surface-hover: var(--hop-coastal-75);
  --hop-status-option1-surface-disabled: var(--hop-coastal-25);
  --hop-status-option1-surface: var(--hop-coastal-50);
  --hop-status-option1-icon-selected: var(--hop-coastal-700);
  --hop-status-option1-icon-press: var(--hop-coastal-800);
  --hop-status-option1-icon-hover: var(--hop-coastal-700);
  --hop-status-option1-icon-disabled: var(--hop-coastal-200);
  --hop-status-option1-icon: var(--hop-coastal-600);
  --hop-status-option1-border-selected: var(--hop-coastal-700);
  --hop-status-option1-border-press: var(--hop-coastal-500);
  --hop-status-option1-border-hover: var(--hop-coastal-400);
  --hop-status-option1-border-disabled: var(--hop-coastal-50);
  --hop-status-option1-border: var(--hop-coastal-300);
  --hop-status-neutral-text-selected: var(--hop-samoyed);
  --hop-status-neutral-text-press: var(--hop-rock-900);
  --hop-status-neutral-text-hover: var(--hop-rock-700);
  --hop-status-neutral-text-disabled: var(--hop-rock-300);
  --hop-status-neutral-text: var(--hop-rock-800);
  --hop-status-neutral-surface-strong: var(--hop-rock-200);
  --hop-status-neutral-surface-selected: var(--hop-rock-800);
  --hop-status-neutral-surface-press: var(--hop-rock-50);
  --hop-status-neutral-surface-hover: var(--hop-rock-25);
  --hop-status-neutral-surface-disabled: var(--hop-rock-50);
  --hop-status-neutral-surface: var(--hop-samoyed);
  --hop-status-neutral-icon-selected: var(--hop-samoyed);
  --hop-status-neutral-icon-press: var(--hop-rock-900);
  --hop-status-neutral-icon-hover: var(--hop-rock-700);
  --hop-status-neutral-icon-disabled: var(--hop-rock-300);
  --hop-status-neutral-icon: var(--hop-rock-800);
  --hop-status-neutral-border-selected: var(--hop-rock-800);
  --hop-status-neutral-border-press: var(--hop-rock-900);
  --hop-status-neutral-border-hover: var(--hop-rock-700);
  --hop-status-neutral-border-disabled: var(--hop-rock-50);
  --hop-status-neutral-border: var(--hop-rock-800);
  --hop-status-negative-text-selected: var(--hop-amanita-700);
  --hop-status-negative-text-press: var(--hop-amanita-800);
  --hop-status-negative-text-hover: var(--hop-amanita-700);
  --hop-status-negative-text-disabled: var(--hop-amanita-200);
  --hop-status-negative-text: var(--hop-amanita-600);
  --hop-status-negative-surface-strong: var(--hop-amanita-200);
  --hop-status-negative-surface-selected: var(--hop-amanita-50);
  --hop-status-negative-surface-press: var(--hop-amanita-100);
  --hop-status-negative-surface-hover: var(--hop-amanita-75);
  --hop-status-negative-surface-disabled: var(--hop-amanita-25);
  --hop-status-negative-surface: var(--hop-amanita-50);
  --hop-status-negative-icon-selected: var(--hop-amanita-700);
  --hop-status-negative-icon-press: var(--hop-amanita-800);
  --hop-status-negative-icon-hover: var(--hop-amanita-700);
  --hop-status-negative-icon-disabled: var(--hop-amanita-200);
  --hop-status-negative-icon: var(--hop-amanita-600);
  --hop-status-negative-border-selected: var(--hop-amanita-700);
  --hop-status-negative-border-press: var(--hop-amanita-500);
  --hop-status-negative-border-hover: var(--hop-amanita-400);
  --hop-status-negative-border-disabled: var(--hop-amanita-50);
  --hop-status-negative-border: var(--hop-amanita-300);
  --hop-status-inactive-text-selected: var(--hop-rock-700);
  --hop-status-inactive-text-press: var(--hop-rock-800);
  --hop-status-inactive-text-hover: var(--hop-rock-700);
  --hop-status-inactive-text-disabled: var(--hop-rock-200);
  --hop-status-inactive-text: var(--hop-rock-600);
  --hop-status-inactive-surface-strong: var(--hop-rock-100);
  --hop-status-inactive-surface-selected: var(--hop-rock-50);
  --hop-status-inactive-surface-press: var(--hop-rock-100);
  --hop-status-inactive-surface-hover: var(--hop-rock-75);
  --hop-status-inactive-surface-disabled: var(--hop-rock-25);
  --hop-status-inactive-surface: var(--hop-rock-50);
  --hop-status-inactive-icon-selected: var(--hop-rock-700);
  --hop-status-inactive-icon-press: var(--hop-rock-800);
  --hop-status-inactive-icon-hover: var(--hop-rock-700);
  --hop-status-inactive-icon-disabled: var(--hop-rock-200);
  --hop-status-inactive-icon: var(--hop-rock-600);
  --hop-status-inactive-border-selected: var(--hop-rock-700);
  --hop-status-inactive-border-press: var(--hop-rock-500);
  --hop-status-inactive-border-hover: var(--hop-rock-400);
  --hop-status-inactive-border-disabled: var(--hop-rock-50);
  --hop-status-inactive-border: var(--hop-rock-300);
  --hop-status-caution-text-selected: var(--hop-koi-700);
  --hop-status-caution-text-press: var(--hop-koi-800);
  --hop-status-caution-text-hover: var(--hop-koi-700);
  --hop-status-caution-text-disabled: var(--hop-koi-200);
  --hop-status-caution-text: var(--hop-koi-600);
  --hop-status-caution-surface-strong: var(--hop-koi-200);
  --hop-status-caution-surface-selected: var(--hop-koi-50);
  --hop-status-caution-surface-press: var(--hop-koi-100);
  --hop-status-caution-surface-hover: var(--hop-koi-75);
  --hop-status-caution-surface-disabled: var(--hop-koi-25);
  --hop-status-caution-surface: var(--hop-koi-50);
  --hop-status-caution-icon-selected: var(--hop-koi-700);
  --hop-status-caution-icon-press: var(--hop-koi-800);
  --hop-status-caution-icon-hover: var(--hop-koi-700);
  --hop-status-caution-icon-disabled: var(--hop-koi-200);
  --hop-status-caution-icon: var(--hop-koi-600);
  --hop-status-caution-border-selected: var(--hop-koi-700);
  --hop-status-caution-border-press: var(--hop-koi-500);
  --hop-status-caution-border-hover: var(--hop-koi-400);
  --hop-status-caution-border-disabled: var(--hop-koi-50);
  --hop-status-caution-border: var(--hop-koi-300);
  --hop-information-text-weak: var(--hop-coastal-300);
  --hop-information-text: var(--hop-coastal-800);
  --hop-information-surface-weak: var(--hop-coastal-50);
  --hop-information-surface-strong: var(--hop-coastal-100);
  --hop-information-surface: var(--hop-coastal-25);
  --hop-information-icon-weak: var(--hop-coastal-300);
  --hop-information-icon-weakest: var(--hop-coastal-75);
  --hop-information-icon: var(--hop-coastal-700);
  --hop-information-border: var(--hop-coastal-100);
  --hop-decorative-option1-text-weak: var(--hop-sapphire-300);
  --hop-decorative-option1-text: var(--hop-sapphire-900);
  --hop-decorative-option1-surface-weakest: var(--hop-sapphire-25);
  --hop-decorative-option1-surface-weak-hover: var(--hop-sapphire-75);
  --hop-decorative-option1-surface-weak: var(--hop-sapphire-50);
  --hop-decorative-option1-surface-strong: var(--hop-sapphire-200);
  --hop-decorative-option1-surface-hover: var(--hop-sapphire-200);
  --hop-decorative-option1-surface: var(--hop-sapphire-100);
  --hop-decorative-option1-icon: var(--hop-sapphire-900);
  --hop-decorative-option1-border: var(--hop-sapphire-100);
  --hop-decorative-option2-text-weak: var(--hop-quetzal-300);
  --hop-decorative-option2-text: var(--hop-quetzal-900);
  --hop-decorative-option2-surface-weakest: var(--hop-quetzal-25);
  --hop-decorative-option2-surface-weak-hover: var(--hop-quetzal-75);
  --hop-decorative-option2-surface-weak: var(--hop-quetzal-50);
  --hop-decorative-option2-surface-strong: var(--hop-quetzal-100);
  --hop-decorative-option2-surface-hover: var(--hop-quetzal-100);
  --hop-decorative-option2-surface: var(--hop-quetzal-75);
  --hop-decorative-option2-icon: var(--hop-quetzal-900);
  --hop-decorative-option2-border: var(--hop-quetzal-100);
  --hop-decorative-option3-text-weak: var(--hop-koi-300);
  --hop-decorative-option3-text: var(--hop-koi-900);
  --hop-decorative-option3-surface-weakest: var(--hop-koi-25);
  --hop-decorative-option3-surface-weak-hover: var(--hop-koi-75);
  --hop-decorative-option3-surface-weak: var(--hop-koi-50);
  --hop-decorative-option3-surface-strong: var(--hop-koi-200);
  --hop-decorative-option3-surface-hover: var(--hop-koi-200);
  --hop-decorative-option3-surface: var(--hop-koi-100);
  --hop-decorative-option3-icon: var(--hop-koi-900);
  --hop-decorative-option3-border: var(--hop-koi-100);
  --hop-decorative-option4-text-weak: var(--hop-moss-400);
  --hop-decorative-option4-text: var(--hop-moss-900);
  --hop-decorative-option4-surface-weakest: var(--hop-moss-25);
  --hop-decorative-option4-surface-weak-hover: var(--hop-moss-75);
  --hop-decorative-option4-surface-weak: var(--hop-moss-50);
  --hop-decorative-option4-surface-strong: var(--hop-moss-100);
  --hop-decorative-option4-surface-hover: var(--hop-moss-100);
  --hop-decorative-option4-surface: var(--hop-moss-75);
  --hop-decorative-option4-icon: var(--hop-moss-900);
  --hop-decorative-option4-border: var(--hop-moss-100);
  --hop-decorative-option6-text-weak: var(--hop-sunken-treasure-200);
  --hop-decorative-option6-text: var(--hop-sunken-treasure-900);
  --hop-decorative-option6-surface-weakest: var(--hop-sunken-treasure-25);
  --hop-decorative-option6-surface-weak-hover: var(--hop-sunken-treasure-75);
  --hop-decorative-option6-surface-weak: var(--hop-sunken-treasure-50);
  --hop-decorative-option6-surface-strong: var(--hop-sunken-treasure-100);
  --hop-decorative-option6-surface-hover: var(--hop-sunken-treasure-100);
  --hop-decorative-option6-surface: var(--hop-sunken-treasure-75);
  --hop-decorative-option6-icon: var(--hop-sunken-treasure-900);
  --hop-decorative-option6-border: var(--hop-sunken-treasure-100);
  --hop-decorative-option5-text-weak: var(--hop-coastal-300);
  --hop-decorative-option5-text: var(--hop-coastal-900);
  --hop-decorative-option5-surface-weakest: var(--hop-coastal-25);
  --hop-decorative-option5-surface-weak-hover: var(--hop-coastal-75);
  --hop-decorative-option5-surface-weak: var(--hop-coastal-50);
  --hop-decorative-option5-surface-strong: var(--hop-coastal-100);
  --hop-decorative-option5-surface-hover: var(--hop-coastal-100);
  --hop-decorative-option5-surface: var(--hop-coastal-75);
  --hop-decorative-option5-icon: var(--hop-coastal-900);
  --hop-decorative-option5-border: var(--hop-coastal-100);
  --hop-decorative-option7-text-weak: var(--hop-toad-500);
  --hop-decorative-option7-text: var(--hop-toad-900);
  --hop-decorative-option7-surface-weakest: var(--hop-toad-25);
  --hop-decorative-option7-surface-weak-hover: var(--hop-toad-75);
  --hop-decorative-option7-surface-weak: var(--hop-toad-50);
  --hop-decorative-option7-surface-strong: var(--hop-toad-100);
  --hop-decorative-option7-surface-hover: var(--hop-toad-100);
  --hop-decorative-option7-surface: var(--hop-toad-75);
  --hop-decorative-option7-icon: var(--hop-toad-900);
  --hop-decorative-option7-border: var(--hop-toad-100);
  --hop-decorative-option8-text-weak: var(--hop-amanita-300);
  --hop-decorative-option8-text: var(--hop-amanita-900);
  --hop-decorative-option8-surface-weakest: var(--hop-amanita-25);
  --hop-decorative-option8-surface-weak-hover: var(--hop-amanita-75);
  --hop-decorative-option8-surface-weak: var(--hop-amanita-50);
  --hop-decorative-option8-surface-strong: var(--hop-amanita-100);
  --hop-decorative-option8-surface-hover: var(--hop-amanita-200);
  --hop-decorative-option8-surface: var(--hop-amanita-75);
  --hop-decorative-option8-icon: var(--hop-amanita-900);
  --hop-decorative-option8-border: var(--hop-amanita-100);
  --hop-decorative-option9-text-weak: var(--hop-rock-300);
  --hop-decorative-option9-text: var(--hop-samoyed);
  --hop-decorative-option9-surface-weakest: var(--hop-rock-200);
  --hop-decorative-option9-surface-weak-hover: var(--hop-rock-400);
  --hop-decorative-option9-surface-weak: var(--hop-rock-300);
  --hop-decorative-option9-surface-strong: var(--hop-rock-800);
  --hop-decorative-option9-surface-hover: var(--hop-rock-500);
  --hop-decorative-option9-surface: var(--hop-rock-400);
  --hop-decorative-option9-icon: var(--hop-samoyed);
  --hop-decorative-option9-border: var(--hop-rock-100);
  --hop-upsell-text-weak-press: var(--hop-sunken-treasure-600);
  --hop-upsell-text-weak-hover: var(--hop-sunken-treasure-500);
  --hop-upsell-text-weak: var(--hop-sunken-treasure-300);
  --hop-upsell-text-press: var(--hop-sunken-treasure-900);
  --hop-upsell-text-hover: var(--hop-sunken-treasure-800);
  --hop-upsell-text-disabled: var(--hop-sunken-treasure-400);
  --hop-upsell-text-selected: var(--hop-sunken-treasure-400);
  --hop-upsell-text: var(--hop-sunken-treasure-700);
  --hop-upsell-surface-weak-press: var(--hop-sunken-treasure-200);
  --hop-upsell-surface-weak-hover: var(--hop-sunken-treasure-100);
  --hop-upsell-surface-weak: var(--hop-sunken-treasure-75);
  --hop-upsell-surface-press: var(--hop-sunken-treasure-75);
  --hop-upsell-surface-hover: var(--hop-sunken-treasure-50);
  --hop-upsell-surface-disabled: var(--hop-sunken-treasure-25);
  --hop-upsell-surface-selected: var(--hop-sunken-treasure-50);
  --hop-upsell-surface: var(--hop-sunken-treasure-25);
  --hop-upsell-icon-weak-press: var(--hop-sunken-treasure-600);
  --hop-upsell-icon-weak-hover: var(--hop-sunken-treasure-500);
  --hop-upsell-icon-weak: var(--hop-sunken-treasure-300);
  --hop-upsell-icon-weakest: var(--hop-sunken-treasure-75);
  --hop-upsell-icon-press: var(--hop-sunken-treasure-900);
  --hop-upsell-icon-hover: var(--hop-sunken-treasure-800);
  --hop-upsell-icon-selected: var(--hop-sunken-treasure-400);
  --hop-upsell-icon: var(--hop-sunken-treasure-700);
  --hop-upsell-border-press: var(--hop-sunken-treasure-300);
  --hop-upsell-border-disabled: var(--hop-sunken-treasure-75);
  --hop-upsell-border-selected: var(--hop-sunken-treasure-400);
  --hop-upsell-border: var(--hop-sunken-treasure-100);
  --hop-upsell-text-weak-active: var(--hop-sunken-treasure-600);
  --hop-upsell-text-active: var(--hop-sunken-treasure-900);
  --hop-upsell-surface-weak-active: var(--hop-sunken-treasure-75);
  --hop-upsell-surface-active: var(--hop-sunken-treasure-200);
  --hop-upsell-icon-weak-active: var(--hop-sunken-treasure-600);
  --hop-upsell-icon-active: var(--hop-sunken-treasure-900);
  --hop-upsell-border-active: var(--hop-sunken-treasure-200);
  --hop-primary-text-strong-hover: var(--hop-samoyed);
  --hop-primary-text-strong: var(--hop-samoyed);
  --hop-primary-text-press: var(--hop-sapphire-700);
  --hop-primary-text-hover: var(--hop-sapphire-600);
  --hop-primary-text-disabled: var(--hop-sapphire-200);
  --hop-primary-text-selected: var(--hop-sapphire-500);
  --hop-primary-text: var(--hop-sapphire-500);
  --hop-primary-surface-weak-press: var(--hop-sapphire-75);
  --hop-primary-surface-weak-hover: var(--hop-sapphire-50);
  --hop-primary-surface-weak: var(--hop-sapphire-25);
  --hop-primary-surface-strong-press: var(--hop-sapphire-700);
  --hop-primary-surface-strong-hover: var(--hop-sapphire-600);
  --hop-primary-surface-strong-selected: var(--hop-sapphire-50);
  --hop-primary-surface-strong: var(--hop-sapphire-400);
  --hop-primary-surface-press: var(--hop-sapphire-300);
  --hop-primary-surface-hover: var(--hop-sapphire-200);
  --hop-primary-surface-focus: var(--hop-sapphire-25);
  --hop-primary-surface-disabled: var(--hop-sapphire-200);
  --hop-primary-surface-selected: var(--hop-sapphire-50);
  --hop-primary-surface: var(--hop-sapphire-50);
  --hop-primary-icon-strong-hover: var(--hop-samoyed);
  --hop-primary-icon-strong: var(--hop-samoyed);
  --hop-primary-icon-press: var(--hop-sapphire-700);
  --hop-primary-icon-hover: var(--hop-sapphire-600);
  --hop-primary-icon-disabled: var(--hop-sapphire-200);
  --hop-primary-icon-selected: var(--hop-sapphire-500);
  --hop-primary-icon: var(--hop-sapphire-500);
  --hop-primary-border-press: var(--hop-sapphire-300);
  --hop-primary-border-focus: var(--hop-sapphire-500);
  --hop-primary-border-selected: var(--hop-sapphire-400);
  --hop-primary-border: var(--hop-sapphire-400);
  --hop-primary-text-active: var(--hop-sapphire-700);
  --hop-primary-surface-weak-active: var(--hop-sapphire-75);
  --hop-primary-surface-strong-active: var(--hop-sapphire-700);
  --hop-primary-surface-active: var(--hop-sapphire-300);
  --hop-primary-icon-active: var(--hop-sapphire-700);
  --hop-primary-border-active: var(--hop-sapphire-300);
  --hop-neutral-text-weakest: var(--hop-rock-300);
  --hop-neutral-text-weak-selected: var(--hop-rock-600);
  --hop-neutral-text-weak-press: var(--hop-rock-700);
  --hop-neutral-text-weak-hover: var(--hop-rock-600);
  --hop-neutral-text-weak: var(--hop-rock-500);
  --hop-neutral-text-strong: var(--hop-samoyed);
  --hop-neutral-text-press: var(--hop-rock-900);
  --hop-neutral-text-hover: var(--hop-rock-700);
  --hop-neutral-text-disabled: var(--hop-rock-300);
  --hop-neutral-text-selected: var(--hop-samoyed);
  --hop-neutral-text: var(--hop-rock-800);
  --hop-neutral-surface-weakest-hover: var(--hop-rock-50);
  --hop-neutral-surface-weakest-selected: var(--hop-rock-50);
  --hop-neutral-surface-weakest: var(--hop-rock-25);
  --hop-neutral-surface-weak-press: var(--hop-rock-100);
  --hop-neutral-surface-weak-hover: var(--hop-rock-75);
  --hop-neutral-surface-weak-selected: var(--hop-rock-50);
  --hop-neutral-surface-weak: var(--hop-rock-50);
  --hop-neutral-surface-strong: var(--hop-rock-800);
  --hop-neutral-surface-press: var(--hop-rock-50);
  --hop-neutral-surface-hover: var(--hop-rock-25);
  --hop-neutral-surface-disabled: var(--hop-rock-50);
  --hop-neutral-surface-selected: var(--hop-rock-800);
  --hop-neutral-surface: var(--hop-samoyed);
  --hop-neutral-icon-weakest: var(--hop-rock-200);
  --hop-neutral-icon-weak-selected: var(--hop-rock-600);
  --hop-neutral-icon-weak-press: var(--hop-rock-700);
  --hop-neutral-icon-weak-hover: var(--hop-rock-600);
  --hop-neutral-icon-weak: var(--hop-rock-400);
  --hop-neutral-icon-strong-hover: var(--hop-samoyed);
  --hop-neutral-icon-strong: var(--hop-samoyed);
  --hop-neutral-icon-press: var(--hop-rock-900);
  --hop-neutral-icon-hover: var(--hop-rock-700);
  --hop-neutral-icon-disabled: var(--hop-rock-300);
  --hop-neutral-icon-selected: var(--hop-samoyed);
  --hop-neutral-icon: var(--hop-rock-800);
  --hop-neutral-border-weakest: var(--hop-rock-50);
  --hop-neutral-border-weak: var(--hop-rock-75);
  --hop-neutral-border-strong-hover: var(--hop-rock-700);
  --hop-neutral-border-strong: var(--hop-rock-800);
  --hop-neutral-border-press: var(--hop-rock-400);
  --hop-neutral-border-hover: var(--hop-rock-300);
  --hop-neutral-border-disabled: var(--hop-rock-50);
  --hop-neutral-border-selected: var(--hop-rock-800);
  --hop-neutral-border: var(--hop-rock-200);
  --hop-neutral-text-weak-active: var(--hop-rock-700);
  --hop-neutral-text-active: var(--hop-rock-900);
  --hop-neutral-surface-weak-active: var(--hop-rock-100);
  --hop-neutral-surface-active: var(--hop-rock-50);
  --hop-neutral-icon-weak-active: var(--hop-rock-700);
  --hop-neutral-icon-active: var(--hop-rock-900);
  --hop-neutral-border-active: var(--hop-rock-400);
  --hop-danger-text-weak-press: var(--hop-amanita-600);
  --hop-danger-text-weak-hover: var(--hop-amanita-500);
  --hop-danger-text-weak: var(--hop-amanita-400);
  --hop-danger-text-strong-hover: var(--hop-samoyed);
  --hop-danger-text-strong: var(--hop-samoyed);
  --hop-danger-text-press: var(--hop-amanita-800);
  --hop-danger-text-hover: var(--hop-amanita-500);
  --hop-danger-text-disabled: var(--hop-amanita-100);
  --hop-danger-text-selected: var(--hop-amanita-400);
  --hop-danger-text: var(--hop-amanita-700);
  --hop-danger-surface-weak-press: var(--hop-amanita-100);
  --hop-danger-surface-weak-hover: var(--hop-amanita-75);
  --hop-danger-surface-weak: var(--hop-amanita-50);
  --hop-danger-surface-strong-hover: var(--hop-amanita-500);
  --hop-danger-surface-strong: var(--hop-amanita-400);
  --hop-danger-surface-press: var(--hop-amanita-600);
  --hop-danger-surface-hover: var(--hop-amanita-500);
  --hop-danger-surface-disabled: var(--hop-amanita-100);
  --hop-danger-surface-selected: var(--hop-amanita-50);
  --hop-danger-surface: var(--hop-amanita-25);
  --hop-danger-icon-weakest: var(--hop-amanita-75);
  --hop-danger-icon-weak-press: var(--hop-amanita-600);
  --hop-danger-icon-weak-hover: var(--hop-amanita-500);
  --hop-danger-icon-weak: var(--hop-amanita-400);
  --hop-danger-icon-strong-hover: var(--hop-samoyed);
  --hop-danger-icon-strong: var(--hop-samoyed);
  --hop-danger-icon-press: var(--hop-amanita-800);
  --hop-danger-icon-hover: var(--hop-amanita-500);
  --hop-danger-icon-disabled: var(--hop-amanita-100);
  --hop-danger-icon-selected: var(--hop-amanita-400);
  --hop-danger-icon: var(--hop-amanita-700);
  --hop-danger-border-press: var(--hop-amanita-300);
  --hop-danger-border-strong: var(--hop-amanita-300);
  --hop-danger-border-selected: var(--hop-amanita-400);
  --hop-danger-border: var(--hop-amanita-100);
  --hop-danger-text-weak-active: var(--hop-amanita-600);
  --hop-danger-text-active: var(--hop-amanita-800);
  --hop-danger-surface-weak-active: var(--hop-amanita-100);
  --hop-danger-surface-active: var(--hop-amanita-600);
  --hop-danger-icon-weak-active: var(--hop-amanita-600);
  --hop-danger-icon-active: var(--hop-amanita-800);
  --hop-danger-border-active: var(--hop-amanita-600);
}
