@import url("./tokens.css");
@import url("./rehype.css");

@font-face {
    font-family: "ABC Favorit Mono";
    font-style: normal;
    font-weight: 400;
    src: url("https://cdn.platform.workleap.com/hopper/fonts/abc-favorit/mono/alternative/ABCFavoritMono-Regular.woff2") format("woff2-variations");
    font-display: fallback;
}

@font-face {
    font-family: "ABC Favorit";
    font-style: normal;
    font-weight: 100 900;
    src: url("https://cdn.platform.workleap.com/hopper/fonts/abc-favorit/alternative/ABCFavoritVariable.woff2") format("woff2-variations");
    font-display: fallback;
}

* {
    padding: 0;
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

*:not([class*="hop-"]):focus-visible {
    border-radius: var(--hd-border-radius-sm);
    box-shadow: var(--hd-focus-ring);
    outline: none;
}

::selection {
    background: var(--hd-color-surface-neutral-selection);
}

body {
    font-family: var(--hd-default-font-family);
    font-size: var(--hd-default-font-size);
    color: var(--hd-color-neutral-text);
    background-color: var(--hd-color-neutral-surface);
}

a {
    color: inherit;
    text-decoration: none;
}

[data-theme="dark"] {
    color-scheme: dark;
}

.hd-flex {
    display: flex;
}

.hd-display {
    font-size: var(--hd-step-5);
    font-weight: 500;
}

.hd-display__subtitle {
    line-height: 1.35;
    font-size: var(--hd-step-2);
}

.hd-text--strong {
    position: relative;
    font-weight: 900;
}

.hd-text--strong::after {
    position: absolute;
    bottom: -0.4rem;
    left: 0;
    right: 0;
    display: flex;
    content: '';
    background: var(--hd-color-primary-surface);
    height: 2rem;
    z-index: -1;
}

.hd-display__subtitle .hd-text--strong::after {
    bottom: -0.2rem;
    height: 1rem;
}

.highlight {
    background: #1ea7fd2b;
    border-radius: 0.25rem;
}

/* Sample */
.hd-sample {
    border: var(--hd-border-size) solid var(--hd-color-neutral-border);
    border-radius: 0.5rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
}

.hd-sample__wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: flex-start;
}

.hd-sample-off-centered-text::before,
.hd-sample-off-centered-text::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
}

.hd-sample-text-offset .myBadge {
    border: none;
    border-radius: 0.5rem;
    background-color: #4767FE;
    color: #FFFFFF;
    height: 1.5rem;
    padding: 0 0.5rem;
    text-transform: uppercase;
    display: inline-flex;
}

.myBadge .hd-sample-off-centered-text.fixed::before {
    margin-bottom: -0.25rem;
}

.myBadge .hd-sample-off-centered-text.fixed::after {
    margin-top: -0.25rem;
}

.myBadge .hd-sample-off-centered-text {
    align-self: center;
    font-family: "ABC Favorit Mono", monospace;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.3333;
}

.myHeadline {
    box-shadow: inset 0 0 0 1rem rgba(199 222 184 / 80%);
    margin-top: 1rem;
    max-width: 20ch;
    padding: 1rem;
}

.myHeadline .hd-sample-off-centered-text {
    font-family: "ABC Favorit", sans-serif;
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: 580;
}

.myHeadline .hd-sample-off-centered-text.fixed::before {
    margin-bottom: -0.125rem;
}

.myHeadline .hd-sample-off-centered-text.fixed::after {
    margin-top: -0.3333rem;
}
