@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 100 900;
	src: url("https://cdn.platform.workleap.com/hopper/fonts/inter/v4/InterVariable.woff2") format("woff2-variations");
	font-display: fallback;
}

@font-face {
	font-family: "ABC Favorit Mono";
	font-style: normal;
	font-weight: 400;
	src: url("https://cdn.platform.workleap.com/hopper/fonts/abc-favorit/mono/alternative/ABCFavoritMono-Regular.woff2") format("woff2-variations");
	font-display: fallback;
}

@font-face {
	font-family: "ABC Favorit";
	font-style: normal;
	font-weight: 100 900;
	src: url("https://cdn.platform.workleap.com/hopper/fonts/abc-favorit/alternative/ABCFavoritVariable.woff2") format("woff2-variations");
	font-display: fallback;
}
