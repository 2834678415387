:root {
    --hd-codeblock-background-color: var(--hd-neutral-800);
    --hd-codeblock-border-color: var(--hd-color-neutral-border);
    --hd-codeblock-border-radius: var(--hd-border-radius-md);
    --hd-codeblock-border-size: 0.125rem;
    --hd-codeblock-padding: var(--hd-space-2);
    --hd-codeblock-line-line-number-color: var(--hd-neutral-400);
    --hd-codeblock-line-highlighted-background-color: #434C5E;
    --hd-codeblock-line-highlighted-border-color: #88C0D0;
    --hd-codeblock-word-selection-background-color: #4B586A;
    --hd-codeblock-word-color: #AF168D;
}

code[data-theme*=' '],
code[data-theme*=' '] span {
    color: var(--shiki-light);
}

[data-theme="dark"] {
    code[data-theme*=' '],
    code[data-theme*=' '] span {
        color: var(--shiki-dark, var(--hd-color-neutral-text));
    }
}

.hd-props-table [style*="--shiki-light: #98C379"] {
    --shiki-light: var(--hd-color-primary-text) !important
}

.hd-props-table [style*="--shiki-light: #E06C75"],
.hd-props-table[style*="--shiki-light: #C678DD"] {
    --shiki-light: var(--hd-codeblock-word-color) !important
}

.hd-props-table [style*="--shiki-light: #56B6C2"] {
    --shiki-light: var(--hd-neutral-700) !important
}

.hd-props-table [style*="--shiki-light: #E5C07B"] {
    --shiki-light: var(--hd-color-accent-text) !important
}

[data-rehype-pretty-code-figure] pre {
    padding-block: var(--hd-codeblock-padding);
    padding-inline: var(--hd-codeblock-padding);
    background-color: var(--hd-codeblock-background-color);
    border-radius: var(--hd-codeblock-border-radius);
}

[data-rehype-pretty-code-figure] pre code {
    display: grid;
    overflow-x: auto;
}

[data-rehype-pretty-code-figure] [data-line] {
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 0 var(--hd-space-1) 0 0;
    border-left: 0.125rem solid transparent;
}

[data-rehype-pretty-code-figure] .word {
    border-radius: var(--hd-border-radius-sm);
    background-color: var(--hd-codeblock-word-selection-background-color);
    box-shadow: 0 0 0 var(--hd-space-05) var(--hd-codeblock-word-selection-background-color);
}

[data-rehype-pretty-code-figure] pre code [data-highlighted-chars] {
    background-color: var(--hd-codeblock-line-highlighted-background-color);
    border-left-color: var(--hd-codeblock-line-highlighted-border-color);
}

/* LINE NUMBERS */
[data-rehype-pretty-code-figure] code[data-line-numbers] {
    counter-reset: line;
}

[data-rehype-pretty-code-figure] code[data-line-numbers] > [data-line]::before {
    counter-increment: line;
    content: counter(line);
    display: inline-block;
    margin-right: var(--hd-space-2);
    min-width: var(--hd-space-2);
    text-align: right;
    color: var(--hd-codeblock-line-line-number-color);
}

/* !* Block Title *! */
[data-rehype-pretty-code-title] {
    display: none;
}

/* !* Block Caption *! */
[data-rehype-pretty-code-caption] {
    margin-top: var(--hd-space-1);
    color: var(--hd-color-primary-text);
    border-radius: var(--hd-space-1);
    font-style: italic;
    font-size: .875rem;
}


.highlighted {
    background-color: var(--hd-codeblock-line-highlighted-background-color);
}
